import { Col, Container, Row } from "react-bootstrap";
import HeaderBanner from "../../components/header-banner/HeaderBanner";
import { Carousel } from "react-responsive-carousel";
import { useEffect, useState } from "react";
import Subtitle from "../../components/Subtitle";
import { supabase } from "../../services/supabase";

function Apartments() {
  useEffect(() => {
    const fetchApartments = async () => {
      try {
        // Fetch apartments
        const { data: apartmentsData, error: apartmentsError } = await supabase
          .from("apartments")
          .select("*");

        if (apartmentsError) throw apartmentsError;

        // Fetch prices
        const { data: pricesData, error: pricesError } = await supabase
          .from("apartment_prices")
          .select("*");

        if (pricesError) throw pricesError;

        // Transform data
        const result = apartmentsData.map((apartment) => ({
          id: apartment.id,
          name: apartment.name,
          description: apartment.description,
          prices: pricesData
            .filter((price) => price.apartment_id === apartment.id)
            .map((price) => ({
              description: price.description,
              price: `$ ${price.price}`,
            })),
        }));

        setApartment1(result[0]);
        setApartment2(result[1]);
        setApartment3(result[2]);
        setApartment4(result[3]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchApartments();
  }, []);

  const apt1 = Array.from({ length: 11 }, (_, i) => i + 1);
  const [apartment1, setApartment1] = useState({
    name: "Por Fin La Casita",
    description: "Departamento 1 habitación",
    prices: [
      {
        description: "1 Persona",
        price: "Consultar",
      },
      {
        description: "2 Personas",
        price: "Consultar",
      },
      {
        description: "3 Personas",
        price: "Consultar",
      },
      {
        description: "4 Personas",
        price: "Consultar",
      },
    ],
  });

  const apt2 = Array.from({ length: 6 }, (_, i) => i + 1);
  const [apartment2, setApartment2] = useState({
    name: "Por Fin La Cajita",
    description: "Monoambiente",
    prices: [
      {
        description: "1 Persona",
        price: "Consultar",
      },
      {
        description: "2 Personas",
        price: "Consultar",
      },
      {
        description: "3 Personas",
        price: "Consultar",
      },
      {
        description: "4 Personas",
        price: "Consultar",
      },
    ],
  });

  const apt3 = Array.from({ length: 7 }, (_, i) => i + 1);
  const [apartment3, setApartment3] = useState({
    name: "Por Fin Lo Encontramos",
    description: "Departamento 2 habitaciones",
    prices: [
      {
        description: "4 Personas",
        price: "Consultar",
      },
      {
        description: "5 Personas",
        price: "Consultar",
      },
      {
        description: "6 Personas",
        price: "Consultar",
      },
    ],
  });

  const apt4 = Array.from({ length: 11 }, (_, i) => i + 1);
  const [apartment4, setApartment4] = useState({
    name: "Por Fin Lo Arreglamos",
    description: "Departamento 2 habitaciones",
    prices: [
      {
        description: "6 Personas",
        price: "Consultar",
      },
      {
        description: "7 Personas",
        price: "Consultar",
      },
      {
        description: "8 Personas",
        price: "Consultar",
      },
      {
        description: "9 Personas",
        price: "Consultar",
      },
      {
        description: "10 Personas",
        price: "Consultar",
      },
      {
        description: "11 Personas",
        price: "Consultar",
      },
      {
        description: "12 Personas",
        price: "Consultar",
      },
    ],
  });

  return (
    <div>
      <HeaderBanner image={"img/apartments_banner.webp"}></HeaderBanner>

      <Container className="mt-5 mb-5">
        <Row>
          <Col xs={12} md={6}>
            <ImageCarousel
              path={"img/apartments/apt1"}
              list={apt1}
            ></ImageCarousel>
          </Col>
          <Col xs={12} md={6} className="mt-md-0 mt-5">
            <ApartmentDetails {...apartment1}></ApartmentDetails>
          </Col>
        </Row>
      </Container>

      <Container className="mt-5 mb-5">
        <Row>
          <Col xs={12} md={6} className="mt-md-0 mt-5 order-2 order-md-1">
            <ApartmentDetails {...apartment2}></ApartmentDetails>
          </Col>
          <Col xs={12} md={6} className="order-1 order-md-2">
            <ImageCarousel
              path={"img/apartments/apt2"}
              list={apt2}
            ></ImageCarousel>
          </Col>
        </Row>
      </Container>

      <Container className="mt-5 mb-5">
        <Row>
          <Col xs={12} md={6}>
            <ImageCarousel
              path={"img/apartments/apt3"}
              list={apt3}
            ></ImageCarousel>
          </Col>
          <Col xs={12} md={6} className="mt-md-0 mt-5">
            <ApartmentDetails {...apartment3}></ApartmentDetails>
          </Col>
        </Row>
      </Container>

      <Container className="mt-5 mb-5">
        <Row>
          <Col xs={12} md={6} className="mt-md-0 mt-5 order-2 order-md-1">
            <ApartmentDetails {...apartment4}></ApartmentDetails>
          </Col>
          <Col xs={12} md={6} className="order-1 order-md-2">
            <ImageCarousel
              path={"img/apartments/apt4"}
              list={apt4}
            ></ImageCarousel>
          </Col>
        </Row>
      </Container>

      <Container className="text-center bg-terciary-light w-75 w-md-50 w-lg-25 p-3 mt-5 rounded text-primary fw-medium mb-5">
        <span>
          Para reservar debe consultar disponibilidad por WhatsApp y señar con
          50%
        </span>
      </Container>

      <Container className="mt-5 mb-4">
        <Subtitle text={"Servicios"}></Subtitle>
      </Container>

      <Container className="mb-5">
        <Row className="justify-content-center text-center gap-3">
          <Col xs={12} md={4} lg={3} className="grid justify-content-center">
            <div className="d-flex">
              <img src="img/apartments/pet.png" width={25} alt="" />
              <span className="ms-3">Pet Friendly</span>
            </div>
            <div className="d-flex">
              <img src="img/apartments/directv.png" width={25} alt="" />
              <span className="ms-3">DirecTV Prepago</span>
            </div>
            <div className="d-flex">
              <img src="img/apartments/garage.png" width={25} alt="" />
              <span className="ms-3">Cocheras</span>
            </div>
          </Col>
          <Col xs={12} md={4} lg={3} className="grid justify-content-center">
            <div className="d-flex">
              <img src="img/apartments/garden.png" width={25} alt="" />
              <span className="ms-3">Jardines</span>
            </div>
            <div className="d-flex">
              <img src="img/apartments/ac.png" width={25} alt="" />
              <span className="ms-3">Aire Acondicionado</span>
            </div>
            <div className="d-flex">
              <img src="img/apartments/kitchen.png" width={25} alt="" />
              <span className="ms-3">Cocina equipada</span>
            </div>
          </Col>
          <Col xs={12} md={4} lg={3} className="grid justify-content-center">
            <div className="d-flex">
              <img src="img/apartments/swim.png" width={25} alt="" />
              <span className="ms-3">Pileta</span>
            </div>
            <div className="d-flex">
              <img src="img/apartments/heater.png" width={25} alt="" />
              <span className="ms-3">Calefacción</span>
            </div>
            <div className="d-flex">
              <img src="img/apartments/bed.png" width={25} alt="" />
              <span className="ms-3">Ropa de cama</span>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="mb-5 mt-5 text-center">
        <h4 className="fw-bold" style={{ color: "#DF4F29" }}>
          ¡Renova tu energía en el Rancho!
        </h4>
      </Container>
    </div>
  );
}

function ApartmentDetails({ name, prices, description }) {
  const [isHovered, setIsHovered] = useState(false);
  const bookButton = {
    width: "7rem",
    padding: "0.75rem",
    borderRadius: "2rem",
    backgroundColor: "var(--primary-color)",
    border: "none",
    color: "white",
    textDecoration: isHovered ? "underline" : "none",
  };

  function book() {
    window.open(`https://api.whatsapp.com/send/?phone=%2B5492644456494&text=Hola.+Vi+la+web.%0AQuiero+consultar+disponibilidad+de+fechas.%0ACantidad+de+personas%3A%0AFechas%3A%0ADepartamento%3A+*${encodeURIComponent(
      name
    )}*+&type=phone_number&app_absent=0
`);
  }

  return (
    <>
      <Container className="h-100">
        <Row className="align-content-between h-100">
          <Col xs={12}>
            <div className="d-flex align-items-center ">
              <div className="d-flex flex-column">
                <h3 className="text-primary">{name}</h3>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <h5>{description}</h5>
            {prices.map(({ description, price }, index) => (
              <>
                <div
                  key={index}
                  className={`d-flex  justify-content-between ${
                    index === 0 ? "mt-3" : ""
                  }`}
                >
                  <span className="fs-5">{description}</span>
                  <span className="fs-5 fw-bold">{price}</span>
                </div>
                <hr style={{ lineHeight: "0.1", margin: "0.3rem" }} />
              </>
            ))}
          </Col>
          <Col xs={12}>
            <div className="d-flex justify-content-between mt-5">
              <div className="d-flex flex-column gap-2">
                <div className="d-flex align-items-center">
                  <div>
                    <img src="img/apartments/garage.png" alt="" width={25} />
                  </div>
                  <div>
                    <span style={{ fontSize: "0.9rem" }} className="ms-3">
                      Cochera techada
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <img
                    src="img/apartments/accesibility.png"
                    alt=""
                    width={25}
                  />
                  <span style={{ fontSize: "0.9rem" }} className="ms-3">
                    Acceso a Calle Los Enamorados
                  </span>
                </div>
              </div>

              <div className="align-self-end">
                <button
                  onClick={book}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  style={bookButton}
                >
                  Reservar
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

function ImageCarousel({ path, list }) {
  return (
    <Carousel
      width={375}
      height={375}
      className="d-flex justify-content-center"
      showArrows={true}
      dynamicHeight={true}
      showThumbs={false}
      infiniteLoop={true}
    >
      {list.map((item) => (
        <DivImage key={item} imageSrc={`${path}/${item}.jpg`}></DivImage>
      ))}
    </Carousel>
  );
}

function DivImage({ imageSrc }) {
  return (
    <div
      style={{
        backgroundImage: `url(${imageSrc})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        borderRadius: "20px",
        height: "375px",
      }}
    ></div>
  );
}

export default Apartments;
